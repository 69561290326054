import { Action, Module, VuexModule } from 'vuex-class-modules'
import { Location, Route } from 'vue-router/types/router'
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class AppModule extends VuexModule {
  // state
  _title = 'Katie & Erik'
  _lastRoute: Location | null = null

  // getters
  get title(): string {
    return this._title
  }

  get lastRoute(): Location {
    return this._lastRoute
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions
  @Action
  saveLastRoute(route: Route): void {
    this._lastRoute = {
      name: route?.name || undefined,
      path: route?.path,
      hash: route?.hash,
      query: route?.query,
      params: route?.params,
    }
  }
}

export default new AppModule({ store, name: 'app' })
