















































import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'
import places from '@/data/places'

@Component({
  components: { Main },
  metaInfo() {
    return {
      title: `${app.title} | Things to Do`,
    }
  },
})
export default class ThingsToDo extends Vue {
  places = places
}
