export default [
  {
    label: 'Attractions',
    locations: [
      {
        name: 'High Museum of Art',
        href: 'https://www.high.org/',
        description:
          "If you're into art, this museum is a must-see. It's got works by some big names, like Georgia O'Keeffe and Winslow Homer.",
        address: '1280 Peachtree St NE, Atlanta, GA 30309',
        image:
          'https://www.omnihotels.com/-/media/images/hotels/atlcnn/destinations/atlanta-high-museum.jpg?h=660&la=en&w=1170',
      },
      {
        name: 'Piedmont Park',
        href: 'https://www.piedmontpark.org/',
        description:
          "Atlanta's most popular park is a great place to relax, have a picnic, or go for a walk. There are always events going on, and every Saturday morning there is a farmers market that's a great place to grab a coffee or a small bite.",
        address: '400 Park Dr NE, Atlanta, GA 30306',
        image:
          'https://www.voiceofgoizueta.com/wp-content/uploads/2018/04/GettyImages-613027536.jpg',
      },
      {
        name: 'Beltline',
        href: 'https://beltline.org/',
        description:
          "This 22-mile network of trails created from repurposed railways is highlighted by the eastside trail, which runs through the Old Fourth Ward and Inman Park. It's a great place to go for a walk or a run. You can hop on and off anywhere between Piedmont Park down to Memorial Drive and have no shortage of great shops and restaurants to check out.",
        address: '',
        image:
          'https://afar.brightspotcdn.com/dims4/default/e583ed9/2147483647/strip/false/crop/800x450+0+25/resize/1200x675!/quality/90/?url=https%3A%2F%2Fafar-media-production-web.s3.amazonaws.com%2Fbrightspot%2Fd1%2Fcd%2F7662ec8dfe3af4222add787d0a97%2Foriginal-e367119bcc250bd496b84599ee9c064b.jpg',
      },
      {
        name: 'Krog Street Tunnel',
        href: 'https://discoveratlanta.com/things-to-do/krog-street-tunnel/',
        description:
          'This tunnel linking the Cabbagetown, Reynoldstown, and Inman Park neighborhoods is a popular spot for street art. There are loads of murals and graffiti pieces to check out, and is a popular place to take pictures. The walls along the south edge of the tunnel have lots of comissioned pieces, and is definitely worth exploring. Note: would recomend going during the day, as the tunnel can be a bit sketchy at night.',
        address: '1 Krog St NE, Atlanta, GA 30307',
        image:
          'https://img.atlasobscura.com/BW7QuXpCCL9clzLjRUM_anNQ2YsXDpwGJo8QfxLjX7s/rs:fill:780:520:1/g:ce/q:81/sm:1/scp:1/ar:1/aHR0cHM6Ly9hdGxh/cy1kZXYuczMuYW1h/em9uYXdzLmNvbS91/cGxvYWRzL3BsYWNl/X2ltYWdlcy85NWU3/NWI4Zi01ZjhiLTQ2/YzUtYmQ0ZS03MmEx/MjllNDU0ODJmZmEz/N2U1ZTFmN2NlYmVi/ZjFfMTU3ODk1NTQw/MjRfMDE3NWZlZGQ4/Ml9iLmpwZw.jpg',
      },
      {
        name: 'Forward, Warrior! Mural Project',
        href: 'https://cabbagetown.com/forwardwarrior/',
        description:
          "This mural project is located along the south facing walls east and west of the south edge of Krog Street Tunnel. It is a great place to check out some comissioned street art painted by Atlanta's best artists.",
        address: '685 Wylie St SE, Atlanta, GA 30316',
        image:
          'https://onthegrid.city/imager/s3_amazonaws_com/onthegrid.city/assets/grid/atlanta/cabbagetown/forward-warrior-wall/MuralWall-12_299006722e285f47655d17d1c9136337.jpg',
      },
      {
        name: 'Ponce City Market',
        href: 'https://poncecitymarket.com/',
        description:
          "One of Atlanta's most popular destinations, Ponce City Market has a mix of restaurants, shops, and entertainment. It's in a historic building, formerly serving as a retail store, warehouse and regional office for Sears before becoming City Hall East for Atlanta, so it's a really interesting place to spend an afternoon.",
        address: '675 Ponce De Leon Ave NE, Atlanta, GA 30308',
        image:
          'https://cdn.vox-cdn.com/thumbor/VgJqKrYeThKNNzdqG2Z029UVFiU=/0x0:3600x2400/1200x0/filters:focal(0x0:3600x2400):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13083465/ATL_BELTLINE_201809071027.jpg',
      },
      {
        name: 'Krog Street Market',
        href: 'https://www.thekrogdistrict.com/',
        description:
          "This former pot-belly stove and iron-pan factory has been transformed into a bustling food hall in Inman Park and has tons of local and international cuisine. It's a great place to grab a quick bite or sit down for a proper meal as well as grab a drink at the Hop City bar.",
        address: '99 Krog St NE, Atlanta, GA 30307',
        image:
          'https://thegavoice.com/wp-content/uploads/2017/12/Atlanta-food-halls.jpg',
      },
      {
        name: 'Atlanta Botanical Garden',
        href: 'https://atlantabg.org/',
        description:
          'A beautiful oasis in the heart of the city, the Atlanta Botanical Garden offers a wide variety of plants and flowers, art installations, and seasonal events.',
        address: '1345 Piedmont Ave NE, Atlanta, GA 30309',
        image:
          'https://cdn2.atlantamagazine.com/wp-content/uploads/sites/4/2023/05/ABGearthgoddess_courtesy.jpg',
      },
      {
        name: 'Zoo Atlanta',
        href: 'https://zooatlanta.org/',
        description:
          'A fun destination for families or animal lovers, Zoo Atlanta features a wide variety of animals, interactive exhibits, and conservation programs.',
        address: '800 Cherokee Ave SE, Atlanta, GA 30315',
        image:
          'https://img.ctykit.com/cdn/ga-atlanta/images/tr:w-900/zoo-atlanta-2.jpg',
      },
    ],
  },
  {
    label: 'Breakfast',
    locations: [
      {
        name: 'Highland Bakery',
        href: 'https://www.highlandbakery.com/',
        description:
          "This popular bakery is known for its delicious pastries, breakfast menu, and coffee. The bakery has a number of locations around the city, so you're never far from a tasty breakfast.",
        address: '',
        image:
          'https://images.squarespace-cdn.com/content/v1/5af9be17f2e6b134f79fc21f/1536344304617-961N1KLV7KZ3YRX7USE8/%C2%A9KathrynMcCraryPhotographyAtlantaFoodPhotographer-239.jpg?format=2500w',
      },
      {
        name: 'Flying Biscuit',
        href: 'https://flyingbiscuit.com/',
        description:
          "This restaurant is a local favorite, known for its hearty Southern breakfasts. The menu includes classic dishes like biscuits and gravy, as well as healthier options like omelets and granola. With a location at 10th and Piedmont, it's a short walk from the hotel.",
        address: '100 10th St NE, Atlanta, GA 30309',
        image:
          'https://media-cdn.tripadvisor.com/media/photo-s/02/ce/6c/5e/the-flying-biscuit-cafe.jpg',
      },
      {
        name: 'Bantam + Biddy',
        href: 'https://www.bantamandbiddy.com/',
        description:
          'This popular breakfast spot is known for its delicious brunch dishes and its gluten free and vegan options. The menu is constantly changing, but always includes a variety of breakfast sandwiches, salads, and a variety of chicken dishes.',
        address: '1544 Piedmont Ave NE #301, Atlanta, GA 30324',
        image:
          'https://www.bantamandbiddy.com/wp-content/uploads/2019/06/chicken-plate.jpg',
      },
    ],
  },
  {
    label: 'Restaurants',
    locations: [
      {
        name: 'Grindhouse Killer Burgers',
        href: 'https://www.grindhousekillerburgers.com/',
        description:
          'An absolute favorite of both Erik and Katie, this burger chain has a number of locations around the city, and has arguably the best burgers and fries in town (if not anywhere).',
        address:
          '701 Memorial Dr SE, Atlanta, GA 30316 and 1842 Piedmont Ave NE, Atlanta, GA 30324',
        image:
          'https://cloudfront-us-east-1.images.arcpublishing.com/ajc/BZZI7WHOR5HCXACKPDQHUZYTQ4.jpg',
      },
      {
        name: 'Victory Sandwich Bar',
        href: 'https://www.victorysandwichbar.com/',
        description:
          'This small chain is known for its unique slider sized sandwiches, as well as its selection of craft beers. Their bourbon & coke slushies are a must try too.',
        address: '913 Bernina Ave NE, Atlanta, GA 30307',
        image:
          'https://www.decaturga.com/sites/default/files/imageattachments/busdir/10181/dvc_decatur-ga-directory_vic1.jpg',
      },
      {
        name: 'Sweet Auburn BBQ',
        href: 'https://www.sweetauburnbbq.com/',
        description:
          'Located in the Poncey-Highlands neighborhood, this restaurant is known for its slow-smoked barbecue and Southern sides. Their smoked old fashioned is a popular drink and pairs well with their barbecue.',
        address: '656 North Highland Avenue Northeast, Atlanta, GA 30306',
        image:
          'https://duyt4h9nfnj50.cloudfront.net/resized/49f245f5db470039aeb0fbd477ef584b-w2880-9c.jpg',
      },
      {
        name: 'Rreal Tacos',
        href: 'https://www.rrealtacos.com/',
        description:
          'This taqueria is a local favorite, known for its authentic Mexican tacos and other dishes.',
        address: '100 6th St NE #110, Atlanta, GA 30308',
        image:
          'https://cdn2.atlantamagazine.com/wp-content/uploads/sites/4/2016/03/EmilySchultz-2016-RrealTacos-2-1-696x464.jpg',
      },
      {
        name: "Mary Mac's Tea Room",
        href: 'https://www.marymacs.com/',
        description:
          'This iconic restaurant is a must-visit for anyone interested in Southern cooking. The menu includes classic dishes like fried chicken, collard greens, and black-eyed peas.',
        address: '224 Ponce De Leon Ave NE, Atlanta, GA 30308',
        image:
          'https://cloudfront-us-east-1.images.arcpublishing.com/ajc/KP3QDFG7DSRTNGVHCHSOKGSDKU.jpg',
      },
      {
        name: 'Antico Pizza Napoletana',
        href: 'https://www.littleitalia.com/',
        description:
          "Known for its authentic Neapolitan pizza, Antico offers a casual dining experience with communal seating. Don't miss their classic Margherita or pepperoni pies.",
        address: '1093 Hemphill Ave NW, Atlanta, GA 30318',
        image:
          'https://cdn.vox-cdn.com/thumbor/pEEDSouA5syS081y8ZyVU52a7U0=/0x0:1000x750/1200x800/filters:focal(420x295:580x455)/cdn.vox-cdn.com/uploads/chorus_image/image/71457720/o.0.jpg',
      },
      {
        name: 'Taqueria del Sol',
        href: 'https://www.taqueriadelsol.com/',
        description:
          'A local favorite, Taqueria del Sol offers a fusion of Southern and Mexican cuisine. Enjoy their famous tacos, enchiladas, and margaritas in a lively, casual setting. Note: there is usually a line out the door, but it moves quickly!',
        address: '1200-B Howell Mill Rd NW, Atlanta, GA 30318',
        image:
          'https://cdn2.atlantamagazine.com/wp-content/uploads/sites/4/2020/01/taqueriadelsol01_feitenphotography_courtesy.jpg',
      },
      {
        name: "Fellini's Pizza",
        href: 'https://www.fellinisatlanta.com/',
        description:
          "A beloved local pizza joint, Fellini's is famous for its delicious Sicilian slices. With a laid-back, artistic atmosphere, it's a great spot to enjoy a casual meal.",
        address: '909 Ponce De Leon Ave NE, Atlanta, GA 30306',
        image:
          'https://media-cdn.tripadvisor.com/media/photo-s/1c/db/15/f7/20210409-114733-largejpg.jpg',
      },
    ],
  },
  {
    label: 'Bars',
    locations: [
      {
        name: 'Clermont Lounge',
        href: 'https://www.clermontlounge.net/',
        description:
          'This popular bar is located in the basement of the Clermont Hotel. It is known for its eclectic atmosphere, non-traditional live adult entertainment, and strong drinks.',
        address: '789 Ponce De Leon Ave NE, Atlanta, GA 30306',
        image:
          'https://cdn.cnn.com/cnnnext/dam/assets/190201135421-clermont-lounge-blondie-full-169.jpg',
      },
      {
        name: 'Clermont Roof',
        href: 'https://www.hotelclermont.com/eat-drink',
        description:
          'This rooftop bar is located on the top floor of the Clermont Hotel, and offers great views of the city. The bar has a laid-back atmosphere, and is a great place to relax and unwind.',
        address: '789 Ponce De Leon Ave NE, Atlanta, GA 30306',
        image:
          'https://www.therooftopguide.com/rooftop-bars-in-atlanta/Bilder/hotel-clermont-rooftop-600-3.jpg',
      },
      {
        name: 'Clermont Hotel Bar',
        href: 'https://www.hotelclermont.com/eat-drink',
        description:
          'This bar is located on the first floor of the Clermont Hotel and has a cozy, intimate atmosphere and a creative cocktail menu.',
        address: '789 Ponce De Leon Ave NE, Atlanta, GA 30306',
        image:
          'https://cdn.vox-cdn.com/thumbor/uwFfT1bmjSFZ95Kw9EQ5h2rhS-A=/0x0:3888x2592/1200x0/filters:focal(0x0:3888x2592):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/11570861/IMG_7672.jpg',
      },
      {
        name: 'Park Tavern',
        href: 'https://www.parktavern.com/',
        description:
          'This popular bar and restaurant is located on the southeast corner of Piedmont Park, and offers great views of the city. The menu includes a variety of beers and cocktails, as well as a selection of pub fare.',
        address: '500 10th St NE, Atlanta, GA 30309',
        image:
          'https://resizer.otstatic.com/v2/photos/wide-huge/1/26083010.jpg',
      },
      {
        name: "Smith's Olde Bar",
        href: 'https://www.smithsoldebar.com/',
        description:
          'This popular music venue is located in the Midtown neighborhood, and features live music almost every night. The bar also has a full menu, including a selection of beers and cocktails.',
        address: '1578 Piedmont Ave NE, Atlanta, GA 30324',
        image:
          'https://popmenucloud.com/cdn-cgi/image/width%3D2400%2Cheight%3D2400%2Cfit%3Dscale-down%2Cformat%3Dauto%2Cquality%3D60/rhxidafk/41c54b73-8cc9-4fed-b3fb-dba961174fd4.jpg',
      },
      {
        name: 'Politan Row at Colony Square',
        href: 'https://www.politanrow.com/',
        description:
          'This foodhall is located in the newly renovated Colony Square building and features a variety of restaurants and bars. The foodhall has a fun, lively atmosphere, and is a great place to grab a bite to eat and a drink.',
        address: '1197 Peachtree St NE Suite 150, Atlanta, GA 30361',
        image:
          'https://images.squarespace-cdn.com/content/v1/5e8e29e68c7849565b84ee51/1657230556661-K4GXUFQ1FT48ATLNO4VJ/ColonySq_PolitanRow_VIPNight_AAAA1572-2.jpg?format=2500w',
      },
      {
        name: "Jojo's Beloved",
        href: 'https://www.jojosbeloved.com/',
        description:
          "Hidden in the back of Colony Square, Jojo's Beloved is a spirited cocktail lounge with a cozy, intimate 1970s atmosphere. The bar has a creative cocktail menu, and is a great place to grab a drink and unwind. Note: highly recommend making a reservation a couple weeks out as it can be very hard to get in here, especially on weekends.",
        address: '1197 Peachtree St NE, Atlanta, GA 30309',
        image:
          'https://cdn.vox-cdn.com/thumbor/nFEOoY41nyaYOtIsDX9rFFkRdug=/1400x788/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22728121/JoJos_Interior1_Photo_Credit_Lindsay_Butler.jpg',
      },
      {
        name: 'Sister Louisa’s Church of the Living Room & Ping Pong Emporium',
        href: 'https://www.sisterlouisaschurch.com/',
        description:
          'Known locally as "Church," this quirky bar offers ping pong, church organ karaoke, and eclectic religious-themed decor. A fun and unique spot for a night out.',
        address: '466 Edgewood Ave SE, Atlanta, GA 30312',
        image:
          'https://secretatlanta.co/wp-content/uploads/2021/02/Webp.net-resizeimage-11-1024x641.png',
      },
      {
        name: 'Joystick Gamebar',
        href: 'https://www.joystickgamebar.com/',
        description:
          'This bar offers a variety of classic arcade games, as well as a selection of beers and cocktails. A fun spot for a night out with friends.',
        address: '427 Edgewood Ave SE, Atlanta, GA 30312',
        image:
          'https://media.cntraveler.com/photos/5b4e5aecb353636ad7e30a2f/16:9/w_2560,c_limit/Joystick-Gamebar__2018_DSC_9665.jpg',
      },
      {
        name: 'The Local',
        href: 'https://www.instagram.com/localonponce/?hl=en',
        description:
          'The Local is a quintessential dive bar located in Atlanta, renowned for its unpretentious ambiance and friendly crowd. What truly sets this place apart is its mouth-watering wings, which have become something of a local legend. Whether you prefer them spicy, tangy, or classic Buffalo style, the wings at The Local are a must-try.',
        address: '758 Ponce De Leon Ave NE, Atlanta, GA 30306',
        image: 'https://creativeloafing.com/dl10137?display&x=650&y=431',
      },
    ],
  },
]
